<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">外事业务</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="from-box">
      <div class="line-box">
        <div class="line-title">护照类型</div>
        <el-select v-model="params.PassportType" placeholder="请选择">
          <el-option
            v-for="item in PassportTypes"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">护照号码</div>
        <el-input v-model="params.PassportCode" placeholder="护照号码" />
      </div>
      <div class="line-box">
        <div class="line-title">护照有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.PassportEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">护照签发地</div>
        <el-input v-model="params.IssueLocation" placeholder="护照号码" />
      </div>
      <div class="line-box">
        <div class="line-title">护照保存地</div>
        <el-select v-model="params.PassportHoldLocation" placeholder="请选择">
          <el-option
            v-for="item in HoldLocations"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">境外公共安全培训情况</div>
        <el-select v-model="params.OutSafeTrain" placeholder="请选择">
          <el-option
            v-for="item in OutSafeTrains"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">境外公共安全培训证书有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.OutSafeTrainEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">任务批件情况</div>
        <el-select v-model="params.MissionDocuments" placeholder="请选择">
          <el-option
            v-for="item in MissionDocuments"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">任务批件有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.MissionDocValidDate"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">体检信息</div>
        <el-select v-model="params.PhysicalsResult" placeholder="请选择">
          <el-option
            v-for="item in PhysicalsResults"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="line-box">
        <div class="line-title">体检有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.ValidityEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">暂住证号码</div>
        <el-input v-model="params.ShackLicenceCode" placeholder="暂住证号码" />
      </div>
      <div class="line-box">
        <div class="line-title">暂住证有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.ShackLicenceEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">暂住证保存地</div>
        <el-select
          v-model="params.ShackLicenceHoldLocation"
          placeholder="请选择"
        >
          <el-option
            v-for="item in HoldLocations"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">沙特医保</div>
        <el-select
          v-model="params.MedicalInsuranceStatusID"
          placeholder="请选择"
        >
          <el-option
            v-for="item in MedicalInsuranceStatus"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">沙特医保有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.MedicalInsuranceEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">工伤保险</div>
        <el-select
          v-model="params.IndustrialInsuranceStatusID"
          placeholder="请选择"
        >
          <el-option
            v-for="item in IndustrialInsuranceStatus"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">工伤保险有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.IndustrialInsuranceEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">境外人生保险</div>
        <el-select v-model="params.JoinInsuranceStatusID" placeholder="请选择">
          <el-option
            v-for="item in JoinInsuranceStatus"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">境外人生保险有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.JoinInsuranceEndTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">外事关系</div>
        <el-select
          v-model="params.ForeignRelationStatusID"
          placeholder="请选择"
        >
          <el-option
            v-for="item in ForeignRelationStatus"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">人员所在地信息</div>
        <el-select v-model="params.PersonnelLocation" placeholder="请选择">
          <el-option
            v-for="item in PersonnelLocations"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">签证类型</div>
        <el-select v-model="params.EntryVisaType" placeholder="请选择">
          <el-option
            v-for="item in EntryVisaTypes"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">SPONSOR</div>
        <el-select v-model="params.SPONSOR" placeholder="请选择">
          <el-option
            v-for="item in Sponsors"
            :key="item.Value"
            :label="item.Text"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">首次入沙时间</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.DatetoFirstEntrySaudi"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">入境号</div>
        <el-input v-model="params.EntryNO" placeholder="入境号" />
      </div>
      <div class="line-box">
        <div class="line-title">过往出国经历</div>
        <el-input
          v-model="params.OverseasWorkExperience"
          placeholder="过往出国经历"
        />
      </div>
      <div class="line-box">
        <div class="line-title">最近一次海外工作撤场记录</div>
        <el-input
          v-model="params.LatestDemobilizationInformation"
          placeholder="最近一次海外工作撤场记录"
        />
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="addCompanies">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getForeignAffair, getForeignAffairBasic } from "@/api/other";
export default {
  data() {
    return {
      params: {
        ID: null,
        PassportType: null,
        PassportCode: null,
        PassportEndTime: null,
        IssueLocation: null,
        PassportHoldLocation: null,
        OutSafeTrain: null,
        OutSafeTrainEndTime: null,
        MissionDocuments: null,
        MissionDocValidDate: null,
        PhysicalsResult: null,
        ValidityEndTime: null,
        ShackLicenceCode: null,
        ShackLicenceEndTime: null,
        ShackLicenceHoldLocation: null,
        MedicalInsuranceStatusID: null,
        MedicalInsuranceEndTime: null,
        IndustrialInsuranceStatusID: null,
        IndustrialInsuranceEndTime: null,
        JoinInsuranceStatusID: null,
        JoinInsuranceEndTime: null,
        ForeignRelationStatusID: null,
        PersonnelLocation: null,
        EntryVisaType: null,
        SPONSOR: null,
        EntryNO: null,
        DatetoFirstEntrySaudi: null,
        OverseasWorkExperience: null,
        LatestDemobilizationInformation: null,
      },
      PassportTypes: [], //护照类型
      OutSafeTrains: [], //境外公共安全培训情况
      MissionDocuments: [], //任务批件
      PhysicalsResults: [], //体检结果
      HoldLocations: [], //护照/暂住证保存地
      MedicalInsuranceStatus: [], //沙特医保状态
      IndustrialInsuranceStatus: [], //工伤保险状态
      JoinInsuranceStatus: [], //境外人保办理状态
      ForeignRelationStatus: [], //外事关系码表ID
      PersonnelLocations: [], //人员所在国家
      EntryVisaTypes: [], //入境签证类型
      Sponsors: [], //Sponsors
    };
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    uploadAttachment() {},
    addCompanies() {},
    //信息
    getForeignAffair() {
      getForeignAffair().then((res) => {
        if (res.status === 200) {
          this.params = res.response;
        }
      });
    },
    //依赖
    getForeignAffairBasic() {
      getForeignAffairBasic().then((res) => {
        if (res.status === 200) {
          const data = res.response;
          this.PassportTypes = data.PassportTypes;
          this.OutSafeTrains = data.OutSafeTrains;
          this.MissionDocuments = data.MissionDocuments;
          this.PhysicalsResults = data.PhysicalsResults;
          this.MedicalInsuranceStatus = data.MedicalInsuranceStatus.map(
            (item) => {
              return { ...item, Value: parseInt(item.Value) };
            }
          );
          this.IndustrialInsuranceStatus = data.IndustrialInsuranceStatus.map(
            (item) => {
              return { ...item, Value: parseInt(item.Value) };
            }
          );
          this.JoinInsuranceStatus = data.JoinInsuranceStatus.map((item) => {
            return { ...item, Value: parseInt(item.Value) };
          });
          this.ForeignRelationStatus = data.ForeignRelationStatus.map(
            (item) => {
              return { ...item, Value: parseInt(item.Value) };
            }
          );
          this.PersonnelLocations = data.PersonnelLocations.map((item) => {
            return { ...item, Value: parseInt(item.Value) };
          });
          this.EntryVisaTypes = data.EntryVisaTypes.map((item) => {
            return { ...item, Value: parseInt(item.Value) };
          });
          this.Sponsors = data.Sponsors.map((item) => {
            return { ...item, Value: parseInt(item.Value) };
          });
        }
      });
    },
  },

  created() {
    this.getForeignAffair();
    this.getForeignAffairBasic();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .from-box {
    width: 100%;
    min-height: calc(100% - 40px);
    background-color: #fff;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: flex-start;
    .line-box {
      width: 30%;
      margin-bottom: 24px;
      .line-title {
        font-size: 14px;
        color: #100303;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .bulue-title {
        color: #257eff;
        cursor: pointer;
      }
    }
    .line-box2 {
      width: 24%;
    }
    .line-box3 {
      width: 49%;
    }
    .line-upload {
      width: 100%;
    }
    .line {
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bolder;
    }
    .upload-load {
      display: flex;
      height: 34px;
      //   margin-top: 24px;
      .left {
        width: 100%;
        border: 1px solid #d2d6de;
        border-right: none;
        .name {
          display: flex;
          align-items: center;
          height: 34px;
          color: #555;
          font-size: 14px;
          text-indent: 2px;
        }
      }
      .btn-up {
        padding: 0;
        margin: 0;
        border: none;
        width: 90px;
        flex-shrink: 0;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #3c8dbc;
        border: 1px solid #367fa9;
        color: #fff;
        font-size: 14px;
        .icon {
          margin-right: 5px;
        }
      }
      .disabled {
        cursor: no-drop;
      }
      .btn-up:hover {
        border-color: #204d74;
        background-color: #367fa9;
      }
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
