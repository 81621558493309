import request from "@/utils/request";
/*
2024年7月30号后的其它接口
*/

//员工照片上传
export function photoUpload(data) {
  return request({
    url: "/api/v1/photo/upload",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

/*
1、消息管理相关
*/

// 1-1 消息管理列表
export function getNews(params) {
  return request({
    url: "/api/v1/news",
    method: "get",
    params,
  });
}

//1-2 新增消息
export function addNews(data) {
  return request({
    url: "/api/v1/news/add",
    method: "post",
    data,
  });
}

//1-3 删除消息
export function deleteNews(params) {
  return request({
    url: "/api/v1/news/delete",
    method: "delete",
    params,
  });
}

/* 
2、基本信息
*/

// 2-1 获取基本信息
export function getGeneral(params) {
  return request({
    url: "/api/v1/employee/general",
    method: "get",
    params,
  });
}

// 2-2 获取基本信息
export function getGeneralBasic(params) {
  return request({
    url: "/api/v1/general/basic",
    method: "get",
    params,
  });
}

//2-3 修改基本信息
export function updateGeneral(data) {
  return request({
    url: "/api/v1/employee/general/update",
    method: "post",
    data,
  });
}
/* 
3、劳动关系
*/

// 2-1 获取劳动关系
export function getLaborRelation(params) {
  return request({
    url: "/api/v1/employee/laborRelation",
    method: "get",
    params,
  });
}

// 2-2 获取基本信息
export function getLaborRelationBasic(params) {
  return request({
    url: "/api/v1/laborRelation/basic",
    method: "get",
    params,
  });
}

//2-3 专业
export function getPositionCategory(params) {
  return request({
    url: "/api/v1/positionCategory/all",
    method: "get",
    params,
  });
}

/* 
4、专业技术
*/

// 4-1 获取专业技术
export function getProfessionalTech(params) {
  return request({
    url: "/api/v1/employee/professionalTech",
    method: "get",
    params,
  });
}

// 4-2 获取基本信息
export function getProfessionalTechBasic(params) {
  return request({
    url: "/api/v1/professionalTech/basic",
    method: "get",
    params,
  });
}

/* 
5、银行账户
*/
// 5-1 获取银行账户
export function getBankAccount(params) {
  return request({
    url: "/api/v1/employee/bankAccount",
    method: "get",
    params,
  });
}

// 5-2 获取基本信息
export function getBankAccountBasic(params) {
  return request({
    url: "/api/v1/bankAccount/basic",
    method: "get",
    params,
  });
}

/* 
6、外事业务
*/
// 6-1 获取外事业务
export function getForeignAffair(params) {
  return request({
    url: "/api/v1/employee/foreignAffair",
    method: "get",
    params,
  });
}
// 6-2 获取基本信息
export function getForeignAffairBasic(params) {
  return request({
    url: "/api/v1/foreignAffair/basic",
    method: "get",
    params,
  });
}
